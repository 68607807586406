import _, { isString } from 'lodash'

import { oktaAuth, OktaAuthInterceptor } from '../../../okta/config'
import { Env } from './env'
import { SubscriptionServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'
import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import {
  CancelReservationRequest,
  ListReservationsRequest,
  ListReservationsResponse,
} from '@nxcr-org/web-api-interface/lib/subscription_service_pb'
import { UpdateVehicleRequest } from '@nxcr-org/web-api-interface/lib/vehicle_listing_service_pb'
import { Vehicle } from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import {
  LineItem,
  Product,
  Subscription,
} from '@nxcr-org/web-api-interface/lib/domain_pb'
import { ValidCancellationReasons } from './reservation.formatted-enums'
import { spyOnResponse } from '../../../utils/spyOnResponse'
import { ListProgramPricingRequest } from '@nxcr-org/web-api-interface/lib/billing_service_pb'

export const ReservationService = {
  getReservations,
  listPrograms,
  cancelReservation,
}

function subscriptionClient(): SubscriptionServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new SubscriptionServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

function billingClient(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

async function getReservations(
  params: ListReservationsRequest.AsObject = {
    pageState: '0',
    pageSize: 200,
    programId: null,
    query: '',
  }
): Promise<ListReservationsResponse.AsObject> {
  const hasQuery = !!params.query
  const client = subscriptionClient()

  const request = new ListReservationsRequest()

  request.setPageState(params.pageState)
  request.setPageSize(params.pageSize)

  if (params.programId !== 'all' && !hasQuery) {
    request.setProgramId(params.programId)
  }

  if (params.query) {
    request.setQuery(`${params.query}*`.replace(/\s/g, ''))
  }

  return client
    .listReservations(request)
    .then(spyOnResponse('List Reservations'))
    .then((res) => {
      return res.toObject()
    })
}

async function listPrograms() {
  const client = billingClient()

  const request = new ListProgramPricingRequest()
  request.setRemovehiddenprices(false)
  request.setRemovehiddenprograms(false)

  return client.listProgramPricing(request).then((programs) => {
    return programs.toObject()
  })
}

export type PaymentsRequest = {
  vehicleId: string
  monthly: number
  securityDeposit: number
  start: number
  // reservationDeposit: number
}

export function buildLineItems({
  monthly,
  start,
  securityDeposit,
}: Omit<PaymentsRequest, 'vehicleId'>) {
  const monthlyLineItem = new LineItem()
  monthlyLineItem.setAmount(monthly * 100)
  monthlyLineItem.setItemType(0)

  const monthlyProduct = new Product()
  monthlyProduct.setProductType(1)
  monthlyProduct.setAmount(monthly)
  monthlyLineItem.setProduct(monthlyProduct)

  const startPaymentLineItem = new LineItem()
  startPaymentLineItem.setAmount(start * 100)
  startPaymentLineItem.setItemType(1)

  const depositLineItem = new LineItem()
  depositLineItem.setAmount(securityDeposit * 100)
  depositLineItem.setItemType(0)

  const depositProduct = new Product()
  depositProduct.setProductType(2)
  depositProduct.setAmount(securityDeposit * 100)
  depositLineItem.setProduct(depositProduct)

  return {
    monthlyLineItem,
    startPaymentLineItem,
    depositLineItem,
  }
}

export function buildPaymentsRequest({
  monthly,
  start,
  vehicleId,
  securityDeposit,
}: PaymentsRequest) {
  const request = new UpdateVehicleRequest()
  const vehicle = new Vehicle()

  vehicle.setId(vehicleId)

  const { monthlyLineItem, startPaymentLineItem, depositLineItem } =
    buildLineItems({
      monthly,
      start,
      securityDeposit,
    })

  vehicle.addDefaultLineItems(monthlyLineItem)
  vehicle.addDefaultLineItems(startPaymentLineItem)
  vehicle.addDefaultLineItems(depositLineItem)

  request.setVehicle(vehicle)

  return request
}

export function buildCancelReservationRequest(
  params: CancelReservationRequest.AsObject
) {
  const request = new CancelReservationRequest()

  if (!isString(params.customerId)) {
    throw new Error('Request requires customer ID')
  }

  if (!isString(params.reservationId)) {
    throw new Error('Request requires reservation ID')
  }

  if (
    params.userType !== Subscription.SubscriptionAction.UserType.CUSTOMER &&
    params.userType !== Subscription.SubscriptionAction.UserType.DEALER
  ) {
    throw new Error('User type must be one of the valid user types')
  }

  if (!_.includes(ValidCancellationReasons, params.actionReason)) {
    throw new Error('Cancellation reason must be one of the valid reasons')
  }

  request.setReservationId(params.reservationId)
  request.setCustomerId(params.customerId)
  request.setActionReason(params.actionReason)
  request.setUserType(params.userType)

  return request
}

async function cancelReservation(params: CancelReservationRequest.AsObject) {
  const client = subscriptionClient()
  const request = buildCancelReservationRequest(params)

  return client.cancelReservation(request)
}
