/**
 * @fileoverview gRPC-Web generated client stub for nexus_service
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var appointment_pb = require('./appointment_pb.js')

var billing_service_pb = require('./billing_service_pb.js')

var customer_service_pb = require('./customer_service_pb.js')

var dealer_pb = require('./dealer_pb.js')

var domain_pb = require('./domain_pb.js')

var fleet_service_pb = require('./fleet_service_pb.js')

var insurance_service_pb = require('./insurance_service_pb.js')

var subscription_service_pb = require('./subscription_service_pb.js')

var web_pb = require('./web_pb.js')
const proto = {};
proto.nexus_service = require('./nexus_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.nexus_service.NexusServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.nexus_service.NexusServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetCustomerRequest,
 *   !proto.nexus_service.GetCustomerResponse>}
 */
const methodDescriptor_NexusService_GetCustomer = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetCustomer',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.GetCustomerRequest,
  proto.nexus_service.GetCustomerResponse,
  /**
   * @param {!proto.nexus_service.GetCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetCustomerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetCustomerRequest,
 *   !proto.nexus_service.GetCustomerResponse>}
 */
const methodInfo_NexusService_GetCustomer = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.GetCustomerResponse,
  /**
   * @param {!proto.nexus_service.GetCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetCustomer',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomer,
      callback);
};


/**
 * @param {!proto.nexus_service.GetCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetCustomer',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetCustomerListRequest,
 *   !proto.nexus_service.GetCustomerListResponse>}
 */
const methodDescriptor_NexusService_GetCustomerList = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetCustomerList',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.GetCustomerListRequest,
  proto.nexus_service.GetCustomerListResponse,
  /**
   * @param {!proto.nexus_service.GetCustomerListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetCustomerListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetCustomerListRequest,
 *   !proto.nexus_service.GetCustomerListResponse>}
 */
const methodInfo_NexusService_GetCustomerList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.GetCustomerListResponse,
  /**
   * @param {!proto.nexus_service.GetCustomerListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetCustomerListResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetCustomerListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetCustomerListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetCustomerListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getCustomerList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetCustomerList',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomerList,
      callback);
};


/**
 * @param {!proto.nexus_service.GetCustomerListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetCustomerListResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getCustomerList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetCustomerList',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomerList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetScheduledDateForStatementRequest,
 *   !proto.nexus_service.GetScheduledDateForStatementResponse>}
 */
const methodDescriptor_NexusService_GetScheduledDateForStatement = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetScheduledDateForStatement',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.GetScheduledDateForStatementRequest,
  proto.nexus_service.GetScheduledDateForStatementResponse,
  /**
   * @param {!proto.nexus_service.GetScheduledDateForStatementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetScheduledDateForStatementResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetScheduledDateForStatementRequest,
 *   !proto.nexus_service.GetScheduledDateForStatementResponse>}
 */
const methodInfo_NexusService_GetScheduledDateForStatement = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.GetScheduledDateForStatementResponse,
  /**
   * @param {!proto.nexus_service.GetScheduledDateForStatementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetScheduledDateForStatementResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetScheduledDateForStatementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetScheduledDateForStatementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetScheduledDateForStatementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getScheduledDateForStatement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetScheduledDateForStatement',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetScheduledDateForStatement,
      callback);
};


/**
 * @param {!proto.nexus_service.GetScheduledDateForStatementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetScheduledDateForStatementResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getScheduledDateForStatement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetScheduledDateForStatement',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetScheduledDateForStatement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.SubscriptionPickupDateRequest,
 *   !proto.nexus_service.SubscriptionPickupDateResponse>}
 */
const methodDescriptor_NexusService_SetSubscriptionPickupDate = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/SetSubscriptionPickupDate',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.SubscriptionPickupDateRequest,
  proto.nexus_service.SubscriptionPickupDateResponse,
  /**
   * @param {!proto.nexus_service.SubscriptionPickupDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.SubscriptionPickupDateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.SubscriptionPickupDateRequest,
 *   !proto.nexus_service.SubscriptionPickupDateResponse>}
 */
const methodInfo_NexusService_SetSubscriptionPickupDate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.SubscriptionPickupDateResponse,
  /**
   * @param {!proto.nexus_service.SubscriptionPickupDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.SubscriptionPickupDateResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.SubscriptionPickupDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.SubscriptionPickupDateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.SubscriptionPickupDateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.setSubscriptionPickupDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/SetSubscriptionPickupDate',
      request,
      metadata || {},
      methodDescriptor_NexusService_SetSubscriptionPickupDate,
      callback);
};


/**
 * @param {!proto.nexus_service.SubscriptionPickupDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.SubscriptionPickupDateResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.setSubscriptionPickupDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/SetSubscriptionPickupDate',
      request,
      metadata || {},
      methodDescriptor_NexusService_SetSubscriptionPickupDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.SetReservationProgramRequest,
 *   !proto.nexus_service.SetReservationProgramResponse>}
 */
const methodDescriptor_NexusService_SetReservationProgram = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/SetReservationProgram',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.SetReservationProgramRequest,
  proto.nexus_service.SetReservationProgramResponse,
  /**
   * @param {!proto.nexus_service.SetReservationProgramRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.SetReservationProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.SetReservationProgramRequest,
 *   !proto.nexus_service.SetReservationProgramResponse>}
 */
const methodInfo_NexusService_SetReservationProgram = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.SetReservationProgramResponse,
  /**
   * @param {!proto.nexus_service.SetReservationProgramRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.SetReservationProgramResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.SetReservationProgramRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.SetReservationProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.SetReservationProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.setReservationProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/SetReservationProgram',
      request,
      metadata || {},
      methodDescriptor_NexusService_SetReservationProgram,
      callback);
};


/**
 * @param {!proto.nexus_service.SetReservationProgramRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.SetReservationProgramResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.setReservationProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/SetReservationProgram',
      request,
      metadata || {},
      methodDescriptor_NexusService_SetReservationProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.ListReservationsRequest,
 *   !proto.subscription_service.ListReservationsResponse>}
 */
const methodDescriptor_NexusService_ListReservations = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListReservations',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.ListReservationsRequest,
  subscription_service_pb.ListReservationsResponse,
  /**
   * @param {!proto.subscription_service.ListReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ListReservationsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.ListReservationsRequest,
 *   !proto.subscription_service.ListReservationsResponse>}
 */
const methodInfo_NexusService_ListReservations = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.ListReservationsResponse,
  /**
   * @param {!proto.subscription_service.ListReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ListReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.ListReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.ListReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.ListReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListReservations',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListReservations,
      callback);
};


/**
 * @param {!proto.subscription_service.ListReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.ListReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListReservations',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.GetReservationRequest,
 *   !proto.subscription_service.GetReservationResponse>}
 */
const methodDescriptor_NexusService_GetReservation = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetReservation',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.GetReservationRequest,
  subscription_service_pb.GetReservationResponse,
  /**
   * @param {!proto.subscription_service.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.GetReservationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.GetReservationRequest,
 *   !proto.subscription_service.GetReservationResponse>}
 */
const methodInfo_NexusService_GetReservation = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.GetReservationResponse,
  /**
   * @param {!proto.subscription_service.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.GetReservationResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.GetReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.GetReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetReservation',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetReservation,
      callback);
};


/**
 * @param {!proto.subscription_service.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.GetReservationResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetReservation',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.UpdateReservationRequest,
 *   !proto.subscription_service.UpdateReservationResponse>}
 */
const methodDescriptor_NexusService_UpdateReservation = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/UpdateReservation',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.UpdateReservationRequest,
  subscription_service_pb.UpdateReservationResponse,
  /**
   * @param {!proto.subscription_service.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.UpdateReservationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.UpdateReservationRequest,
 *   !proto.subscription_service.UpdateReservationResponse>}
 */
const methodInfo_NexusService_UpdateReservation = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.UpdateReservationResponse,
  /**
   * @param {!proto.subscription_service.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.UpdateReservationResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.UpdateReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.UpdateReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.updateReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateReservation',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateReservation,
      callback);
};


/**
 * @param {!proto.subscription_service.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.UpdateReservationResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.updateReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateReservation',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.AddCustomerNoteRequest,
 *   !proto.nexus_service.AddCustomerNoteResponse>}
 */
const methodDescriptor_NexusService_AddCustomerNote = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/AddCustomerNote',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.AddCustomerNoteRequest,
  proto.nexus_service.AddCustomerNoteResponse,
  /**
   * @param {!proto.nexus_service.AddCustomerNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.AddCustomerNoteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.AddCustomerNoteRequest,
 *   !proto.nexus_service.AddCustomerNoteResponse>}
 */
const methodInfo_NexusService_AddCustomerNote = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.AddCustomerNoteResponse,
  /**
   * @param {!proto.nexus_service.AddCustomerNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.AddCustomerNoteResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.AddCustomerNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.AddCustomerNoteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.AddCustomerNoteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.addCustomerNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/AddCustomerNote',
      request,
      metadata || {},
      methodDescriptor_NexusService_AddCustomerNote,
      callback);
};


/**
 * @param {!proto.nexus_service.AddCustomerNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.AddCustomerNoteResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.addCustomerNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/AddCustomerNote',
      request,
      metadata || {},
      methodDescriptor_NexusService_AddCustomerNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetCustomerNotesRequest,
 *   !proto.nexus_service.GetCustomerNotesResponse>}
 */
const methodDescriptor_NexusService_GetCustomerNotes = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetCustomerNotes',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.GetCustomerNotesRequest,
  proto.nexus_service.GetCustomerNotesResponse,
  /**
   * @param {!proto.nexus_service.GetCustomerNotesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetCustomerNotesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetCustomerNotesRequest,
 *   !proto.nexus_service.GetCustomerNotesResponse>}
 */
const methodInfo_NexusService_GetCustomerNotes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.GetCustomerNotesResponse,
  /**
   * @param {!proto.nexus_service.GetCustomerNotesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetCustomerNotesResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetCustomerNotesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetCustomerNotesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetCustomerNotesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getCustomerNotes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetCustomerNotes',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomerNotes,
      callback);
};


/**
 * @param {!proto.nexus_service.GetCustomerNotesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetCustomerNotesResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getCustomerNotes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetCustomerNotes',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomerNotes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.OverrideQualificationRequest,
 *   !proto.customer_service.OverrideQualificationResponse>}
 */
const methodDescriptor_NexusService_OverrideCustomerQualification = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/OverrideCustomerQualification',
  grpc.web.MethodType.UNARY,
  customer_service_pb.OverrideQualificationRequest,
  customer_service_pb.OverrideQualificationResponse,
  /**
   * @param {!proto.customer_service.OverrideQualificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.OverrideQualificationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.OverrideQualificationRequest,
 *   !proto.customer_service.OverrideQualificationResponse>}
 */
const methodInfo_NexusService_OverrideCustomerQualification = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.OverrideQualificationResponse,
  /**
   * @param {!proto.customer_service.OverrideQualificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.OverrideQualificationResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.OverrideQualificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.OverrideQualificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.OverrideQualificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.overrideCustomerQualification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/OverrideCustomerQualification',
      request,
      metadata || {},
      methodDescriptor_NexusService_OverrideCustomerQualification,
      callback);
};


/**
 * @param {!proto.customer_service.OverrideQualificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.OverrideQualificationResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.overrideCustomerQualification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/OverrideCustomerQualification',
      request,
      metadata || {},
      methodDescriptor_NexusService_OverrideCustomerQualification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.RecalculateCustomerScoresRequest,
 *   !proto.customer_service.RecalculateCustomerScoresResponse>}
 */
const methodDescriptor_NexusService_RecalculateCustomerQualification = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/RecalculateCustomerQualification',
  grpc.web.MethodType.UNARY,
  customer_service_pb.RecalculateCustomerScoresRequest,
  customer_service_pb.RecalculateCustomerScoresResponse,
  /**
   * @param {!proto.customer_service.RecalculateCustomerScoresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.RecalculateCustomerScoresResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.RecalculateCustomerScoresRequest,
 *   !proto.customer_service.RecalculateCustomerScoresResponse>}
 */
const methodInfo_NexusService_RecalculateCustomerQualification = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.RecalculateCustomerScoresResponse,
  /**
   * @param {!proto.customer_service.RecalculateCustomerScoresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.RecalculateCustomerScoresResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.RecalculateCustomerScoresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.RecalculateCustomerScoresResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.RecalculateCustomerScoresResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.recalculateCustomerQualification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/RecalculateCustomerQualification',
      request,
      metadata || {},
      methodDescriptor_NexusService_RecalculateCustomerQualification,
      callback);
};


/**
 * @param {!proto.customer_service.RecalculateCustomerScoresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.RecalculateCustomerScoresResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.recalculateCustomerQualification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/RecalculateCustomerQualification',
      request,
      metadata || {},
      methodDescriptor_NexusService_RecalculateCustomerQualification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.GetCQEScoresRequest,
 *   !proto.customer_service.GetCQEScoresResponse>}
 */
const methodDescriptor_NexusService_GetCustomerCQEScores = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetCustomerCQEScores',
  grpc.web.MethodType.UNARY,
  customer_service_pb.GetCQEScoresRequest,
  customer_service_pb.GetCQEScoresResponse,
  /**
   * @param {!proto.customer_service.GetCQEScoresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetCQEScoresResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.GetCQEScoresRequest,
 *   !proto.customer_service.GetCQEScoresResponse>}
 */
const methodInfo_NexusService_GetCustomerCQEScores = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.GetCQEScoresResponse,
  /**
   * @param {!proto.customer_service.GetCQEScoresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetCQEScoresResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.GetCQEScoresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.GetCQEScoresResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.GetCQEScoresResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getCustomerCQEScores =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetCustomerCQEScores',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomerCQEScores,
      callback);
};


/**
 * @param {!proto.customer_service.GetCQEScoresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.GetCQEScoresResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getCustomerCQEScores =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetCustomerCQEScores',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomerCQEScores);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetInsuredDriversRequest,
 *   !proto.insurance_service.GetInsuredDriversResponse>}
 */
const methodDescriptor_NexusService_GetInsuredDrivers = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetInsuredDrivers',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetInsuredDriversRequest,
  insurance_service_pb.GetInsuredDriversResponse,
  /**
   * @param {!proto.insurance_service.GetInsuredDriversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuredDriversResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetInsuredDriversRequest,
 *   !proto.insurance_service.GetInsuredDriversResponse>}
 */
const methodInfo_NexusService_GetInsuredDrivers = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsuredDriversResponse,
  /**
   * @param {!proto.insurance_service.GetInsuredDriversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuredDriversResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetInsuredDriversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsuredDriversResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsuredDriversResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getInsuredDrivers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetInsuredDrivers',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetInsuredDrivers,
      callback);
};


/**
 * @param {!proto.insurance_service.GetInsuredDriversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsuredDriversResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getInsuredDrivers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetInsuredDrivers',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetInsuredDrivers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetInsuranceQuoteRequest,
 *   !proto.insurance_service.GetInsuranceQuoteResponse>}
 */
const methodDescriptor_NexusService_GetInsuranceQuote = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetInsuranceQuote',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetInsuranceQuoteRequest,
  insurance_service_pb.GetInsuranceQuoteResponse,
  /**
   * @param {!proto.insurance_service.GetInsuranceQuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuranceQuoteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetInsuranceQuoteRequest,
 *   !proto.insurance_service.GetInsuranceQuoteResponse>}
 */
const methodInfo_NexusService_GetInsuranceQuote = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsuranceQuoteResponse,
  /**
   * @param {!proto.insurance_service.GetInsuranceQuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuranceQuoteResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetInsuranceQuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsuranceQuoteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsuranceQuoteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getInsuranceQuote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetInsuranceQuote',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetInsuranceQuote,
      callback);
};


/**
 * @param {!proto.insurance_service.GetInsuranceQuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsuranceQuoteResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getInsuranceQuote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetInsuranceQuote',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetInsuranceQuote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.CreateAppointmentRequest,
 *   !proto.nexus_service.CreateAppointmentResponse>}
 */
const methodDescriptor_NexusService_CreateAppointment = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/CreateAppointment',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.CreateAppointmentRequest,
  proto.nexus_service.CreateAppointmentResponse,
  /**
   * @param {!proto.nexus_service.CreateAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.CreateAppointmentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.CreateAppointmentRequest,
 *   !proto.nexus_service.CreateAppointmentResponse>}
 */
const methodInfo_NexusService_CreateAppointment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.CreateAppointmentResponse,
  /**
   * @param {!proto.nexus_service.CreateAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.CreateAppointmentResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.CreateAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.CreateAppointmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.CreateAppointmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.createAppointment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/CreateAppointment',
      request,
      metadata || {},
      methodDescriptor_NexusService_CreateAppointment,
      callback);
};


/**
 * @param {!proto.nexus_service.CreateAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.CreateAppointmentResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.createAppointment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/CreateAppointment',
      request,
      metadata || {},
      methodDescriptor_NexusService_CreateAppointment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.CancelAppointmentRequest,
 *   !proto.nexus_service.CancelAppointmentResponse>}
 */
const methodDescriptor_NexusService_CancelAppointment = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/CancelAppointment',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.CancelAppointmentRequest,
  proto.nexus_service.CancelAppointmentResponse,
  /**
   * @param {!proto.nexus_service.CancelAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.CancelAppointmentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.CancelAppointmentRequest,
 *   !proto.nexus_service.CancelAppointmentResponse>}
 */
const methodInfo_NexusService_CancelAppointment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.CancelAppointmentResponse,
  /**
   * @param {!proto.nexus_service.CancelAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.CancelAppointmentResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.CancelAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.CancelAppointmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.CancelAppointmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.cancelAppointment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/CancelAppointment',
      request,
      metadata || {},
      methodDescriptor_NexusService_CancelAppointment,
      callback);
};


/**
 * @param {!proto.nexus_service.CancelAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.CancelAppointmentResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.cancelAppointment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/CancelAppointment',
      request,
      metadata || {},
      methodDescriptor_NexusService_CancelAppointment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.ListAppointmentsRequest,
 *   !proto.nexus_service.ListAppointmentsResponse>}
 */
const methodDescriptor_NexusService_ListAppointments = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListAppointments',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.ListAppointmentsRequest,
  proto.nexus_service.ListAppointmentsResponse,
  /**
   * @param {!proto.nexus_service.ListAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListAppointmentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.ListAppointmentsRequest,
 *   !proto.nexus_service.ListAppointmentsResponse>}
 */
const methodInfo_NexusService_ListAppointments = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.ListAppointmentsResponse,
  /**
   * @param {!proto.nexus_service.ListAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListAppointmentsResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.ListAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.ListAppointmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.ListAppointmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listAppointments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListAppointments',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListAppointments,
      callback);
};


/**
 * @param {!proto.nexus_service.ListAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.ListAppointmentsResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listAppointments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListAppointments',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListAppointments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.UpdateAppointmentRequest,
 *   !proto.nexus_service.UpdateAppointmentResponse>}
 */
const methodDescriptor_NexusService_UpdateAppointment = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/UpdateAppointment',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.UpdateAppointmentRequest,
  proto.nexus_service.UpdateAppointmentResponse,
  /**
   * @param {!proto.nexus_service.UpdateAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.UpdateAppointmentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.UpdateAppointmentRequest,
 *   !proto.nexus_service.UpdateAppointmentResponse>}
 */
const methodInfo_NexusService_UpdateAppointment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.UpdateAppointmentResponse,
  /**
   * @param {!proto.nexus_service.UpdateAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.UpdateAppointmentResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.UpdateAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.UpdateAppointmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.UpdateAppointmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.updateAppointment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateAppointment',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateAppointment,
      callback);
};


/**
 * @param {!proto.nexus_service.UpdateAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.UpdateAppointmentResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.updateAppointment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateAppointment',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateAppointment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.ListCompletedAppointmentsRequest,
 *   !proto.nexus_service.ListCompletedAppointmentsResponse>}
 */
const methodDescriptor_NexusService_ListCompletedAppointments = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListCompletedAppointments',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.ListCompletedAppointmentsRequest,
  proto.nexus_service.ListCompletedAppointmentsResponse,
  /**
   * @param {!proto.nexus_service.ListCompletedAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListCompletedAppointmentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.ListCompletedAppointmentsRequest,
 *   !proto.nexus_service.ListCompletedAppointmentsResponse>}
 */
const methodInfo_NexusService_ListCompletedAppointments = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.ListCompletedAppointmentsResponse,
  /**
   * @param {!proto.nexus_service.ListCompletedAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListCompletedAppointmentsResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.ListCompletedAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.ListCompletedAppointmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.ListCompletedAppointmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listCompletedAppointments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListCompletedAppointments',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListCompletedAppointments,
      callback);
};


/**
 * @param {!proto.nexus_service.ListCompletedAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.ListCompletedAppointmentsResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listCompletedAppointments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListCompletedAppointments',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListCompletedAppointments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.ListAppointmentTypeConfigsRequest,
 *   !proto.nexus_service.ListAppointmentTypeConfigsResponse>}
 */
const methodDescriptor_NexusService_ListAppointmentTypeConfigs = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListAppointmentTypeConfigs',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.ListAppointmentTypeConfigsRequest,
  proto.nexus_service.ListAppointmentTypeConfigsResponse,
  /**
   * @param {!proto.nexus_service.ListAppointmentTypeConfigsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListAppointmentTypeConfigsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.ListAppointmentTypeConfigsRequest,
 *   !proto.nexus_service.ListAppointmentTypeConfigsResponse>}
 */
const methodInfo_NexusService_ListAppointmentTypeConfigs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.ListAppointmentTypeConfigsResponse,
  /**
   * @param {!proto.nexus_service.ListAppointmentTypeConfigsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListAppointmentTypeConfigsResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.ListAppointmentTypeConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.ListAppointmentTypeConfigsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.ListAppointmentTypeConfigsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listAppointmentTypeConfigs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListAppointmentTypeConfigs',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListAppointmentTypeConfigs,
      callback);
};


/**
 * @param {!proto.nexus_service.ListAppointmentTypeConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.ListAppointmentTypeConfigsResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listAppointmentTypeConfigs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListAppointmentTypeConfigs',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListAppointmentTypeConfigs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.SetAppointmentTypeConfigsRequest,
 *   !proto.nexus_service.SetAppointmentTypeConfigsResponse>}
 */
const methodDescriptor_NexusService_SetAppointmentTypeConfigs = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/SetAppointmentTypeConfigs',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.SetAppointmentTypeConfigsRequest,
  proto.nexus_service.SetAppointmentTypeConfigsResponse,
  /**
   * @param {!proto.nexus_service.SetAppointmentTypeConfigsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.SetAppointmentTypeConfigsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.SetAppointmentTypeConfigsRequest,
 *   !proto.nexus_service.SetAppointmentTypeConfigsResponse>}
 */
const methodInfo_NexusService_SetAppointmentTypeConfigs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.SetAppointmentTypeConfigsResponse,
  /**
   * @param {!proto.nexus_service.SetAppointmentTypeConfigsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.SetAppointmentTypeConfigsResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.SetAppointmentTypeConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.SetAppointmentTypeConfigsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.SetAppointmentTypeConfigsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.setAppointmentTypeConfigs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/SetAppointmentTypeConfigs',
      request,
      metadata || {},
      methodDescriptor_NexusService_SetAppointmentTypeConfigs,
      callback);
};


/**
 * @param {!proto.nexus_service.SetAppointmentTypeConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.SetAppointmentTypeConfigsResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.setAppointmentTypeConfigs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/SetAppointmentTypeConfigs',
      request,
      metadata || {},
      methodDescriptor_NexusService_SetAppointmentTypeConfigs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetAppointmentCheckoutStatusRequest,
 *   !proto.nexus_service.GetAppointmentCheckoutStatusResponse>}
 */
const methodDescriptor_NexusService_GetAppointmentCheckoutStatus = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetAppointmentCheckoutStatus',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.GetAppointmentCheckoutStatusRequest,
  proto.nexus_service.GetAppointmentCheckoutStatusResponse,
  /**
   * @param {!proto.nexus_service.GetAppointmentCheckoutStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetAppointmentCheckoutStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetAppointmentCheckoutStatusRequest,
 *   !proto.nexus_service.GetAppointmentCheckoutStatusResponse>}
 */
const methodInfo_NexusService_GetAppointmentCheckoutStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.GetAppointmentCheckoutStatusResponse,
  /**
   * @param {!proto.nexus_service.GetAppointmentCheckoutStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetAppointmentCheckoutStatusResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetAppointmentCheckoutStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetAppointmentCheckoutStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetAppointmentCheckoutStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getAppointmentCheckoutStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetAppointmentCheckoutStatus',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetAppointmentCheckoutStatus,
      callback);
};


/**
 * @param {!proto.nexus_service.GetAppointmentCheckoutStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetAppointmentCheckoutStatusResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getAppointmentCheckoutStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetAppointmentCheckoutStatus',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetAppointmentCheckoutStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.AppointmentKeyHandOffRequest,
 *   !proto.nexus_service.AppointmentKeyHandOffResponse>}
 */
const methodDescriptor_NexusService_AppointmentKeyHandOff = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/AppointmentKeyHandOff',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.AppointmentKeyHandOffRequest,
  proto.nexus_service.AppointmentKeyHandOffResponse,
  /**
   * @param {!proto.nexus_service.AppointmentKeyHandOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.AppointmentKeyHandOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.AppointmentKeyHandOffRequest,
 *   !proto.nexus_service.AppointmentKeyHandOffResponse>}
 */
const methodInfo_NexusService_AppointmentKeyHandOff = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.AppointmentKeyHandOffResponse,
  /**
   * @param {!proto.nexus_service.AppointmentKeyHandOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.AppointmentKeyHandOffResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.AppointmentKeyHandOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.AppointmentKeyHandOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.AppointmentKeyHandOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.appointmentKeyHandOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/AppointmentKeyHandOff',
      request,
      metadata || {},
      methodDescriptor_NexusService_AppointmentKeyHandOff,
      callback);
};


/**
 * @param {!proto.nexus_service.AppointmentKeyHandOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.AppointmentKeyHandOffResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.appointmentKeyHandOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/AppointmentKeyHandOff',
      request,
      metadata || {},
      methodDescriptor_NexusService_AppointmentKeyHandOff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetAppointmentKeyHandOffRequest,
 *   !proto.nexus_service.GetAppointmentKeyHandOffResponse>}
 */
const methodDescriptor_NexusService_GetAppointmentKeyHandOff = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetAppointmentKeyHandOff',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.GetAppointmentKeyHandOffRequest,
  proto.nexus_service.GetAppointmentKeyHandOffResponse,
  /**
   * @param {!proto.nexus_service.GetAppointmentKeyHandOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetAppointmentKeyHandOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetAppointmentKeyHandOffRequest,
 *   !proto.nexus_service.GetAppointmentKeyHandOffResponse>}
 */
const methodInfo_NexusService_GetAppointmentKeyHandOff = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.GetAppointmentKeyHandOffResponse,
  /**
   * @param {!proto.nexus_service.GetAppointmentKeyHandOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetAppointmentKeyHandOffResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetAppointmentKeyHandOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetAppointmentKeyHandOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetAppointmentKeyHandOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getAppointmentKeyHandOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetAppointmentKeyHandOff',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetAppointmentKeyHandOff,
      callback);
};


/**
 * @param {!proto.nexus_service.GetAppointmentKeyHandOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetAppointmentKeyHandOffResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getAppointmentKeyHandOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetAppointmentKeyHandOff',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetAppointmentKeyHandOff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.ListDealershipsRequest,
 *   !proto.nexus_service.ListDealershipsResponse>}
 */
const methodDescriptor_NexusService_ListDealerships = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListDealerships',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.ListDealershipsRequest,
  proto.nexus_service.ListDealershipsResponse,
  /**
   * @param {!proto.nexus_service.ListDealershipsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListDealershipsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.ListDealershipsRequest,
 *   !proto.nexus_service.ListDealershipsResponse>}
 */
const methodInfo_NexusService_ListDealerships = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.ListDealershipsResponse,
  /**
   * @param {!proto.nexus_service.ListDealershipsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListDealershipsResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.ListDealershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.ListDealershipsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.ListDealershipsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listDealerships =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListDealerships',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListDealerships,
      callback);
};


/**
 * @param {!proto.nexus_service.ListDealershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.ListDealershipsResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listDealerships =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListDealerships',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListDealerships);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.ListDealershipUsersRequest,
 *   !proto.nexus_service.ListDealershipUsersResponse>}
 */
const methodDescriptor_NexusService_ListDealershipUsers = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListDealershipUsers',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.ListDealershipUsersRequest,
  proto.nexus_service.ListDealershipUsersResponse,
  /**
   * @param {!proto.nexus_service.ListDealershipUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListDealershipUsersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.ListDealershipUsersRequest,
 *   !proto.nexus_service.ListDealershipUsersResponse>}
 */
const methodInfo_NexusService_ListDealershipUsers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.ListDealershipUsersResponse,
  /**
   * @param {!proto.nexus_service.ListDealershipUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.ListDealershipUsersResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.ListDealershipUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.ListDealershipUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.ListDealershipUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listDealershipUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListDealershipUsers',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListDealershipUsers,
      callback);
};


/**
 * @param {!proto.nexus_service.ListDealershipUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.ListDealershipUsersResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listDealershipUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListDealershipUsers',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListDealershipUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListPromotionsResponse>}
 */
const methodDescriptor_NexusService_ListPromotions = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListPromotions',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  billing_service_pb.ListPromotionsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListPromotionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListPromotionsResponse>}
 */
const methodInfo_NexusService_ListPromotions = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListPromotionsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListPromotionsResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListPromotionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListPromotionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listPromotions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListPromotions',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListPromotions,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListPromotionsResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listPromotions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListPromotions',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListPromotions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListPromotionPartnersResponse>}
 */
const methodDescriptor_NexusService_ListPromotionPartners = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListPromotionPartners',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  billing_service_pb.ListPromotionPartnersResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListPromotionPartnersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListPromotionPartnersResponse>}
 */
const methodInfo_NexusService_ListPromotionPartners = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListPromotionPartnersResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListPromotionPartnersResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListPromotionPartnersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListPromotionPartnersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listPromotionPartners =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListPromotionPartners',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListPromotionPartners,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListPromotionPartnersResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listPromotionPartners =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListPromotionPartners',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListPromotionPartners);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreatePromotionPartnerRequest,
 *   !proto.billing_service.CreatePromotionPartnerResponse>}
 */
const methodDescriptor_NexusService_CreatePromotionPartner = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/CreatePromotionPartner',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreatePromotionPartnerRequest,
  billing_service_pb.CreatePromotionPartnerResponse,
  /**
   * @param {!proto.billing_service.CreatePromotionPartnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreatePromotionPartnerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreatePromotionPartnerRequest,
 *   !proto.billing_service.CreatePromotionPartnerResponse>}
 */
const methodInfo_NexusService_CreatePromotionPartner = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.CreatePromotionPartnerResponse,
  /**
   * @param {!proto.billing_service.CreatePromotionPartnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreatePromotionPartnerResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreatePromotionPartnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.CreatePromotionPartnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.CreatePromotionPartnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.createPromotionPartner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/CreatePromotionPartner',
      request,
      metadata || {},
      methodDescriptor_NexusService_CreatePromotionPartner,
      callback);
};


/**
 * @param {!proto.billing_service.CreatePromotionPartnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.CreatePromotionPartnerResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.createPromotionPartner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/CreatePromotionPartner',
      request,
      metadata || {},
      methodDescriptor_NexusService_CreatePromotionPartner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreatePromotionRequest,
 *   !proto.billing_service.CreatePromotionResponse>}
 */
const methodDescriptor_NexusService_CreatePromotion = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/CreatePromotion',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreatePromotionRequest,
  billing_service_pb.CreatePromotionResponse,
  /**
   * @param {!proto.billing_service.CreatePromotionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreatePromotionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreatePromotionRequest,
 *   !proto.billing_service.CreatePromotionResponse>}
 */
const methodInfo_NexusService_CreatePromotion = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.CreatePromotionResponse,
  /**
   * @param {!proto.billing_service.CreatePromotionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreatePromotionResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreatePromotionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.CreatePromotionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.CreatePromotionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.createPromotion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/CreatePromotion',
      request,
      metadata || {},
      methodDescriptor_NexusService_CreatePromotion,
      callback);
};


/**
 * @param {!proto.billing_service.CreatePromotionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.CreatePromotionResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.createPromotion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/CreatePromotion',
      request,
      metadata || {},
      methodDescriptor_NexusService_CreatePromotion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ListProgramPricingRequest,
 *   !proto.billing_service.ListProgramPricingResponse>}
 */
const methodDescriptor_NexusService_ListProgramPricing = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListProgramPricing',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ListProgramPricingRequest,
  billing_service_pb.ListProgramPricingResponse,
  /**
   * @param {!proto.billing_service.ListProgramPricingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListProgramPricingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ListProgramPricingRequest,
 *   !proto.billing_service.ListProgramPricingResponse>}
 */
const methodInfo_NexusService_ListProgramPricing = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListProgramPricingResponse,
  /**
   * @param {!proto.billing_service.ListProgramPricingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListProgramPricingResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ListProgramPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListProgramPricingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListProgramPricingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listProgramPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListProgramPricing',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListProgramPricing,
      callback);
};


/**
 * @param {!proto.billing_service.ListProgramPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListProgramPricingResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listProgramPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListProgramPricing',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListProgramPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetVehicleMapRequest,
 *   !proto.nexus_service.GetVehicleMapResponse>}
 */
const methodDescriptor_NexusService_GetMapVehicles = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetMapVehicles',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.GetVehicleMapRequest,
  proto.nexus_service.GetVehicleMapResponse,
  /**
   * @param {!proto.nexus_service.GetVehicleMapRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetVehicleMapResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetVehicleMapRequest,
 *   !proto.nexus_service.GetVehicleMapResponse>}
 */
const methodInfo_NexusService_GetMapVehicles = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.GetVehicleMapResponse,
  /**
   * @param {!proto.nexus_service.GetVehicleMapRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetVehicleMapResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetVehicleMapRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetVehicleMapResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetVehicleMapResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getMapVehicles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetMapVehicles',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetMapVehicles,
      callback);
};


/**
 * @param {!proto.nexus_service.GetVehicleMapRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetVehicleMapResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getMapVehicles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetMapVehicles',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetMapVehicles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.UpdateVehicleCustodyRequest,
 *   !proto.nexus_service.UpdateVehicleCustodyResponse>}
 */
const methodDescriptor_NexusService_UpdateVehicleCustody = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/UpdateVehicleCustody',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.UpdateVehicleCustodyRequest,
  proto.nexus_service.UpdateVehicleCustodyResponse,
  /**
   * @param {!proto.nexus_service.UpdateVehicleCustodyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.UpdateVehicleCustodyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.UpdateVehicleCustodyRequest,
 *   !proto.nexus_service.UpdateVehicleCustodyResponse>}
 */
const methodInfo_NexusService_UpdateVehicleCustody = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.UpdateVehicleCustodyResponse,
  /**
   * @param {!proto.nexus_service.UpdateVehicleCustodyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.UpdateVehicleCustodyResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.UpdateVehicleCustodyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.UpdateVehicleCustodyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.UpdateVehicleCustodyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.updateVehicleCustody =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleCustody',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleCustody,
      callback);
};


/**
 * @param {!proto.nexus_service.UpdateVehicleCustodyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.UpdateVehicleCustodyResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.updateVehicleCustody =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleCustody',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleCustody);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetLatestCustodyRequest,
 *   !proto.nexus_service.GetLatestCustodyResponse>}
 */
const methodDescriptor_NexusService_GetLatestCustody = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetLatestCustody',
  grpc.web.MethodType.UNARY,
  proto.nexus_service.GetLatestCustodyRequest,
  proto.nexus_service.GetLatestCustodyResponse,
  /**
   * @param {!proto.nexus_service.GetLatestCustodyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetLatestCustodyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetLatestCustodyRequest,
 *   !proto.nexus_service.GetLatestCustodyResponse>}
 */
const methodInfo_NexusService_GetLatestCustody = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_service.GetLatestCustodyResponse,
  /**
   * @param {!proto.nexus_service.GetLatestCustodyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_service.GetLatestCustodyResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetLatestCustodyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetLatestCustodyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetLatestCustodyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getLatestCustody =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetLatestCustody',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetLatestCustody,
      callback);
};


/**
 * @param {!proto.nexus_service.GetLatestCustodyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetLatestCustodyResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getLatestCustody =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetLatestCustody',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetLatestCustody);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.ListVehiclesRequest,
 *   !proto.fleet_service.ListVehiclesResponse>}
 */
const methodDescriptor_NexusService_ListVehicles = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/ListVehicles',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.ListVehiclesRequest,
  fleet_service_pb.ListVehiclesResponse,
  /**
   * @param {!proto.fleet_service.ListVehiclesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ListVehiclesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.ListVehiclesRequest,
 *   !proto.fleet_service.ListVehiclesResponse>}
 */
const methodInfo_NexusService_ListVehicles = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.ListVehiclesResponse,
  /**
   * @param {!proto.fleet_service.ListVehiclesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ListVehiclesResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.ListVehiclesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.ListVehiclesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.ListVehiclesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.listVehicles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/ListVehicles',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListVehicles,
      callback);
};


/**
 * @param {!proto.fleet_service.ListVehiclesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.ListVehiclesResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.listVehicles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/ListVehicles',
      request,
      metadata || {},
      methodDescriptor_NexusService_ListVehicles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetVehicleRequest,
 *   !proto.fleet_service.GetVehicleResponse>}
 */
const methodDescriptor_NexusService_GetVehicle = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetVehicleRequest,
  fleet_service_pb.GetVehicleResponse,
  /**
   * @param {!proto.fleet_service.GetVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetVehicleRequest,
 *   !proto.fleet_service.GetVehicleResponse>}
 */
const methodInfo_NexusService_GetVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetVehicleResponse,
  /**
   * @param {!proto.fleet_service.GetVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetVehicle',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.GetVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetVehicle',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UpdateVehicleRequest,
 *   !proto.fleet_service.UpdateVehicleResponse>}
 */
const methodDescriptor_NexusService_UpdateVehicle = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/UpdateVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UpdateVehicleRequest,
  fleet_service_pb.UpdateVehicleResponse,
  /**
   * @param {!proto.fleet_service.UpdateVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UpdateVehicleRequest,
 *   !proto.fleet_service.UpdateVehicleResponse>}
 */
const methodInfo_NexusService_UpdateVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UpdateVehicleResponse,
  /**
   * @param {!proto.fleet_service.UpdateVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UpdateVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UpdateVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UpdateVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.updateVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicle',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.UpdateVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UpdateVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.updateVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicle',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetSubscriptionMileageRequest,
 *   !proto.fleet_service.GetSubscriptionMileageResponse>}
 */
const methodDescriptor_NexusService_GetSubscriptionMileage = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetSubscriptionMileage',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetSubscriptionMileageRequest,
  fleet_service_pb.GetSubscriptionMileageResponse,
  /**
   * @param {!proto.fleet_service.GetSubscriptionMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetSubscriptionMileageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetSubscriptionMileageRequest,
 *   !proto.fleet_service.GetSubscriptionMileageResponse>}
 */
const methodInfo_NexusService_GetSubscriptionMileage = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetSubscriptionMileageResponse,
  /**
   * @param {!proto.fleet_service.GetSubscriptionMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetSubscriptionMileageResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetSubscriptionMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetSubscriptionMileageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetSubscriptionMileageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getSubscriptionMileage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetSubscriptionMileage',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetSubscriptionMileage,
      callback);
};


/**
 * @param {!proto.fleet_service.GetSubscriptionMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetSubscriptionMileageResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getSubscriptionMileage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetSubscriptionMileage',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetSubscriptionMileage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetSubscriptionMileagePurchasesRequest,
 *   !proto.fleet_service.GetSubscriptionMileagePurchasesResponse>}
 */
const methodDescriptor_NexusService_GetSubscriptionMileagePurchases = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/GetSubscriptionMileagePurchases',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetSubscriptionMileagePurchasesRequest,
  fleet_service_pb.GetSubscriptionMileagePurchasesResponse,
  /**
   * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetSubscriptionMileagePurchasesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetSubscriptionMileagePurchasesRequest,
 *   !proto.fleet_service.GetSubscriptionMileagePurchasesResponse>}
 */
const methodInfo_NexusService_GetSubscriptionMileagePurchases = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetSubscriptionMileagePurchasesResponse,
  /**
   * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetSubscriptionMileagePurchasesResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetSubscriptionMileagePurchasesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetSubscriptionMileagePurchasesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.getSubscriptionMileagePurchases =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/GetSubscriptionMileagePurchases',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetSubscriptionMileagePurchases,
      callback);
};


/**
 * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetSubscriptionMileagePurchasesResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.getSubscriptionMileagePurchases =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/GetSubscriptionMileagePurchases',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetSubscriptionMileagePurchases);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UpdateStateToInListingRequest,
 *   !proto.fleet_service.UpdateStateToInListingResponse>}
 */
const methodDescriptor_NexusService_UpdateVehicleStateToInListing = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/UpdateVehicleStateToInListing',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UpdateStateToInListingRequest,
  fleet_service_pb.UpdateStateToInListingResponse,
  /**
   * @param {!proto.fleet_service.UpdateStateToInListingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateStateToInListingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UpdateStateToInListingRequest,
 *   !proto.fleet_service.UpdateStateToInListingResponse>}
 */
const methodInfo_NexusService_UpdateVehicleStateToInListing = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UpdateStateToInListingResponse,
  /**
   * @param {!proto.fleet_service.UpdateStateToInListingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateStateToInListingResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UpdateStateToInListingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UpdateStateToInListingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UpdateStateToInListingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.updateVehicleStateToInListing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleStateToInListing',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleStateToInListing,
      callback);
};


/**
 * @param {!proto.fleet_service.UpdateStateToInListingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UpdateStateToInListingResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.updateVehicleStateToInListing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleStateToInListing',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleStateToInListing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UpdateStateToInProcessRequest,
 *   !proto.fleet_service.UpdateStateToInProcessResponse>}
 */
const methodDescriptor_NexusService_UpdateVehicleStateToInProcess = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/UpdateVehicleStateToInProcess',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UpdateStateToInProcessRequest,
  fleet_service_pb.UpdateStateToInProcessResponse,
  /**
   * @param {!proto.fleet_service.UpdateStateToInProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateStateToInProcessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UpdateStateToInProcessRequest,
 *   !proto.fleet_service.UpdateStateToInProcessResponse>}
 */
const methodInfo_NexusService_UpdateVehicleStateToInProcess = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UpdateStateToInProcessResponse,
  /**
   * @param {!proto.fleet_service.UpdateStateToInProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateStateToInProcessResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UpdateStateToInProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UpdateStateToInProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UpdateStateToInProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.updateVehicleStateToInProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleStateToInProcess',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleStateToInProcess,
      callback);
};


/**
 * @param {!proto.fleet_service.UpdateStateToInProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UpdateStateToInProcessResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.updateVehicleStateToInProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleStateToInProcess',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleStateToInProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UpdateStateToSoldRequest,
 *   !proto.fleet_service.UpdateStateToSoldResponse>}
 */
const methodDescriptor_NexusService_UpdateVehicleStateToSold = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/UpdateVehicleStateToSold',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UpdateStateToSoldRequest,
  fleet_service_pb.UpdateStateToSoldResponse,
  /**
   * @param {!proto.fleet_service.UpdateStateToSoldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateStateToSoldResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UpdateStateToSoldRequest,
 *   !proto.fleet_service.UpdateStateToSoldResponse>}
 */
const methodInfo_NexusService_UpdateVehicleStateToSold = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UpdateStateToSoldResponse,
  /**
   * @param {!proto.fleet_service.UpdateStateToSoldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateStateToSoldResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UpdateStateToSoldRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UpdateStateToSoldResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UpdateStateToSoldResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.updateVehicleStateToSold =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleStateToSold',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleStateToSold,
      callback);
};


/**
 * @param {!proto.fleet_service.UpdateStateToSoldRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UpdateStateToSoldResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.updateVehicleStateToSold =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleStateToSold',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleStateToSold);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UpdateStateToTotalLossRequest,
 *   !proto.fleet_service.UpdateStateToTotalLossResponse>}
 */
const methodDescriptor_NexusService_UpdateVehicleStateToTotalLoss = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/UpdateVehicleStateToTotalLoss',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UpdateStateToTotalLossRequest,
  fleet_service_pb.UpdateStateToTotalLossResponse,
  /**
   * @param {!proto.fleet_service.UpdateStateToTotalLossRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateStateToTotalLossResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UpdateStateToTotalLossRequest,
 *   !proto.fleet_service.UpdateStateToTotalLossResponse>}
 */
const methodInfo_NexusService_UpdateVehicleStateToTotalLoss = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UpdateStateToTotalLossResponse,
  /**
   * @param {!proto.fleet_service.UpdateStateToTotalLossRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateStateToTotalLossResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UpdateStateToTotalLossRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UpdateStateToTotalLossResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UpdateStateToTotalLossResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.updateVehicleStateToTotalLoss =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleStateToTotalLoss',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleStateToTotalLoss,
      callback);
};


/**
 * @param {!proto.fleet_service.UpdateStateToTotalLossRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UpdateStateToTotalLossResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.updateVehicleStateToTotalLoss =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/UpdateVehicleStateToTotalLoss',
      request,
      metadata || {},
      methodDescriptor_NexusService_UpdateVehicleStateToTotalLoss);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.AddVehicleAssignmentRequest,
 *   !proto.subscription_service.AddVehicleAssignmentResponse>}
 */
const methodDescriptor_NexusService_AddVehicleAssignment = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/AddVehicleAssignment',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.AddVehicleAssignmentRequest,
  subscription_service_pb.AddVehicleAssignmentResponse,
  /**
   * @param {!proto.subscription_service.AddVehicleAssignmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.AddVehicleAssignmentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.AddVehicleAssignmentRequest,
 *   !proto.subscription_service.AddVehicleAssignmentResponse>}
 */
const methodInfo_NexusService_AddVehicleAssignment = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.AddVehicleAssignmentResponse,
  /**
   * @param {!proto.subscription_service.AddVehicleAssignmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.AddVehicleAssignmentResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.AddVehicleAssignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.AddVehicleAssignmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.AddVehicleAssignmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.addVehicleAssignment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/AddVehicleAssignment',
      request,
      metadata || {},
      methodDescriptor_NexusService_AddVehicleAssignment,
      callback);
};


/**
 * @param {!proto.subscription_service.AddVehicleAssignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.AddVehicleAssignmentResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.addVehicleAssignment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/AddVehicleAssignment',
      request,
      metadata || {},
      methodDescriptor_NexusService_AddVehicleAssignment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.RemoveVehicleAssignmentRequest,
 *   !proto.subscription_service.RemoveVehicleAssignmentResponse>}
 */
const methodDescriptor_NexusService_RemoveVehicleAssignment = new grpc.web.MethodDescriptor(
  '/nexus_service.NexusService/RemoveVehicleAssignment',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.RemoveVehicleAssignmentRequest,
  subscription_service_pb.RemoveVehicleAssignmentResponse,
  /**
   * @param {!proto.subscription_service.RemoveVehicleAssignmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.RemoveVehicleAssignmentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.RemoveVehicleAssignmentRequest,
 *   !proto.subscription_service.RemoveVehicleAssignmentResponse>}
 */
const methodInfo_NexusService_RemoveVehicleAssignment = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.RemoveVehicleAssignmentResponse,
  /**
   * @param {!proto.subscription_service.RemoveVehicleAssignmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.RemoveVehicleAssignmentResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.RemoveVehicleAssignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.RemoveVehicleAssignmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.RemoveVehicleAssignmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_service.NexusServiceClient.prototype.removeVehicleAssignment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_service.NexusService/RemoveVehicleAssignment',
      request,
      metadata || {},
      methodDescriptor_NexusService_RemoveVehicleAssignment,
      callback);
};


/**
 * @param {!proto.subscription_service.RemoveVehicleAssignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.RemoveVehicleAssignmentResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_service.NexusServicePromiseClient.prototype.removeVehicleAssignment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_service.NexusService/RemoveVehicleAssignment',
      request,
      metadata || {},
      methodDescriptor_NexusService_RemoveVehicleAssignment);
};


module.exports = proto.nexus_service;

