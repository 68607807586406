import React from 'react'
import { Box, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FormError } from 'shared/FormError'

interface EditCustomerAddressFormProps {
  errors
  control
}

export const EditCustomerAddress: React.FC<EditCustomerAddressFormProps> = ({
  errors,
  control,
}) => {
  return (
    <Box>
      <Controller
        name="street"
        control={control}
        rules={{ required: 'This field is required', max: 150 }}
        render={({ field: { onChange, ...rest } }) => {
          return (
            <TextField
              fullWidth
              label="Street Address"
              margin="normal"
              onChange={onChange}
              {...rest}
            />
          )
        }}
      />
      {errors?.street && <FormError text={errors.street.message} />}

      <Controller
        name="street2"
        control={control}
        render={({ field: { onChange, ...rest } }) => {
          return (
            <TextField
              fullWidth
              label="Street Address 2"
              margin="normal"
              onChange={onChange}
              {...rest}
            />
          )
        }}
      />
      {errors?.street2 && <FormError text={errors.street2.message} />}

      <Controller
        name="city"
        control={control}
        rules={{
          required: 'This field is required',
          max: 100,
        }}
        render={({ field: { onChange, ...rest } }) => (
          <TextField
            fullWidth
            label="City"
            margin="normal"
            onChange={onChange}
            {...rest}
          />
        )}
      />
      {errors?.city && <FormError text={errors.city.message} />}

      <Controller
        name="state"
        control={control}
        rules={{
          required: 'This field is required.',
          minLength: {
            value: 2,
            message: 'Minimum 2 characters required.',
          },
          maxLength: {
            value: 2,
            message: 'Maximum 2 characters allowed.',
          },
        }}
        render={({ field: { onChange, ...rest } }) => (
          <TextField
            fullWidth
            label="State"
            margin="normal"
            onChange={onChange}
            {...rest}
          />
        )}
      />
      {errors?.state && <FormError text={errors.state.message} />}

      <Controller
        name="postal"
        control={control}
        rules={{
          required: 'This field is required',
        }}
        render={({ field: { onChange, ...rest } }) => (
          <TextField
            fullWidth
            label="Postal"
            margin="normal"
            onChange={onChange}
            {...rest}
          />
        )}
      />
      {errors?.postal && <FormError text={errors.postal.message} />}
    </Box>
  )
}
