import React, { useState } from 'react'
import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { autonomyColor } from '../../global-styles/autonomyColor'
import { useCustomerContext } from './CustomerContext'
import { Delete } from '@material-ui/icons'
import DeleteDialog from 'shared/DeleteDialog'
import { formattedPhoneNumber } from './useCustomers.utils'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { FormDrawerComponent } from 'shared/FormDrawerComponent'
import useCustomerInfo from './useCustomerInfo'
import { EditCustomerAddress } from './EditCustomerAddress'

export const CustomerInfo = () => {
  const [phoneNumber, setPhoneNumber] = useState(null)
  const { customerInfo, subscriptionStatus } = useCustomerContext()
  const {
    handleCloseFakePhone,
    onUpdateFakePhone,
    isUpdateAddOpen,
    onToggleUpdateAddress,
    onHandleUpdateAddress,
    getDefaultValues,
  } = useCustomerInfo()

  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.pink} variant="subtitle2">
                    Basic Details
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color="inherit" variant="subtitle2"></Link>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Email
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.email}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Phone
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.phone
                ? formattedPhoneNumber(customerInfo?.phone)
                : null}
              {customerInfo?.phone ? (
                <span>
                  <IconButton
                    className="xsIcon"
                    onClick={() => {
                      setPhoneNumber(customerInfo?.phone)
                    }}
                  >
                    <Delete />
                  </IconButton>
                </span>
              ) : null}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Parking Address
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.parkingAddress}{' '}
              <IconButton
                className="xsIcon"
                onClick={() => onToggleUpdateAddress('Parking Address')}
              >
                <BorderColorIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    License Address
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.licenseAddress}{' '}
              <IconButton
                className="xsIcon"
                onClick={() => onToggleUpdateAddress('License Address')}
              >
                <BorderColorIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Contact Address
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.contactAddress}{' '}
              <IconButton
                className="xsIcon"
                onClick={() => onToggleUpdateAddress('Contact Address')}
              >
                <BorderColorIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Subscription status
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {subscriptionStatus}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Account Number
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.accountNumber}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Driver&apos;s License Exp. Date
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.liscenceExpirationDate}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <DeleteDialog
        open={Boolean(phoneNumber)}
        handleClose={handleCloseFakePhone}
        title="Update customer with fake phone number"
        description={`You are about to change the phone number ${customerInfo.phone} to a fake number would you like to proceed?`}
        onConfirm={onUpdateFakePhone}
      />

      {isUpdateAddOpen && (
        <FormDrawerComponent
          isDrawerOpen={
            typeof isUpdateAddOpen === 'string' ? true : isUpdateAddOpen
          }
          setIsDrawerOpen={onToggleUpdateAddress}
          header={`Edit ${isUpdateAddOpen}`}
          handleSave={onHandleUpdateAddress}
          defaultValues={getDefaultValues()}
          form={({ errors, control }) => {
            return <EditCustomerAddress errors={errors} control={control} />
          }}
        />
      )}
    </>
  )
}
